<template>
  <div class="order-detail__row flex-wrap-wrap">
    <div v-if="match.price" class="order-detail__row">
      <p class="order-detail--label" v-text="$t('market.orderDetail.unitPrice')" />
      <p
        class="order-detail--value"
        v-text="
          `${formatNumber({
            num: Number(match?.price),
            precision: pricePrecision,
          })} ${market?.pairs?.payment?.toUpperCase?.()}`
        "
      />
    </div>
    <p-divider class="my-2 full-width" />
    <div v-if="match.amount" class="order-detail__row">
      <p class="order-detail--label" v-text="$t('market.orderDetail.amount')" />
      <p
        class="order-detail--value"
        v-text="
          `${formatNumber({ num: Number(match?.amount), precision: amountPrecision })} ${market?.pairs?.market?.toUpperCase?.()}`
        "
      />
    </div>
    <p-divider class="my-2 full-width" />
    <div v-if="match.price || match.total" class="order-detail__row">
      <p class="order-detail--label" v-text="$t('market.orderDetail.totalPrice')" />
      <p
        class="order-detail--value"
        v-text="
          `${formatNumber({ num: orderTotal, precision: 2 })} ${market?.pairs?.payment?.toUpperCase?.()}`
        "
      />
    </div>
    <p-divider class="my-2 full-width" />
    <div class="order-detail__row">
      <p class="order-detail--label" v-text="$t('market.orderDetail.commission')" />
      <p
        class="order-detail--value"
        v-text="formatPercent({ num: Number(match?.commission_ratio) * 100 })"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ObjectRecord } from '~/models'

const props = defineProps({
  match: {
    type: Object as PropType<ObjectRecord>,
    default: null,
  },
  market: {
    type: Object as PropType<ObjectRecord>,
    default: null,
  },
})

const isConversion = computed(() => {
  return props.match?.entity === 'conversion'
})

const orderTotal = computed(() => {
  if (isConversion.value) {
    return props.match?.total
  }
  return mul({ numA: props.match?.price, numB: props.match?.amount })
})

const pricePrecision = computed(() => {
  return props.market?.[props.match?.market]?.precisions.price || 2
})

const amountPrecision = computed(() => {
  return props.market?.[props.match?.market]?.precisions.amount || 2
})
</script>
