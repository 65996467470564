<template>
  <div class="order-detail">
    <div class="order-detail__container">
      <div class="order-detail__row">
        <p class="order-detail--label" v-text="$t('market.orderDetail.date')" />
        <p
          class="order-detail--value"
          v-text="formatDate({ date: detail?.created_at, format: 'long' })"
        />
      </div>
      <p-divider class="my-2 full-width" />
      <div class="order-detail__row">
        <p class="order-detail--label" v-text="$t('market.orderDetail.processType')" />
        <p
          :class="['order-detail--type', detail?.trade === 'buy' ? 't-fg-accent' : 't-fg-danger']"
          v-text="
            detail?.type
              ? $t(detail?.trade === 'buy' ? 'tradeForm.buy' : 'tradeForm.sell', {
                  currency: getOrderTypeText(detail?.type),
                })
              : ''
          "
        />
      </div>
      <p-divider class="my-2 full-width" />
      <div class="order-detail__row">
        <p class="order-detail--label" v-text="$t('market.orderDetail.status')" />
        <p-tag
          id="test"
          :label="$t(getStatusHash(detail)?.text)"
          :type="$t(getStatusHash(detail)?.type)"
          size="md"
          bg-type="light"
        />
      </div>
      <p-divider class="my-2 full-width" />
      <div class="order-detail__row">
        <p class="order-detail--label" v-text="$t('market.orderDetail.amount')" />
        <p
          class="order-detail--value"
          v-text="
            `${formatNumber({
              num: detail?.amount,
              precision: detail?.precisions?.amount,
            })} ${props.selectedMarket?.pairs?.market?.toUpperCase?.()}`
          "
        />
      </div>
      <p-divider class="my-2 full-width" />
      <div class="order-detail__row">
        <p class="order-detail--label" v-text="$t('market.orderDetail.matchedAmount')" />
        <p
          class="order-detail--value"
          v-text="
            detail?.remaining_amount === null ? '-' : matchedAmount(detail as OpenOrderConfig)
          "
        />
      </div>
      <p-divider class="my-2 full-width" />
      <div class="order-detail__row">
        <p
          v-if="detail?.status === 'open'"
          class="order-detail--label"
          v-text="$t('market.orderDetail.unitPrice')"
        />
        <p v-else class="order-detail--label" v-text="$t('financialHistory.averagePrice')" />
        <p
          class="order-detail--value"
          v-text="
            `${formatNumber({
              num: detail?.status === 'open' ? detail?.price : detail?.average,
              precision: props.selectedMarket?.precisions?.price,
            })} ${props.selectedMarket?.pairs?.payment?.toUpperCase?.()}`
          "
        />
      </div>
      <p-divider class="my-2 full-width" />
      <div class="order-detail__row-last">
        <p class="order-detail--label" v-text="$t('market.orderDetail.totalPrice')" />
        <p
          class="order-detail--value"
          v-text="
            `${formatNumber({
              num: mul({
                numA: Number(detail?.average),
                numB: minus({
                  numA: Number(detail?.amount),
                  numB: Number(detail?.remaining_amount),
                  precision: marketPrecision(detail.market),
                }),
              }),
            })} ${props.selectedMarket?.pairs?.payment?.toUpperCase?.()}`
          "
        />
      </div>
      <div v-if="detail?.status !== 'open' && detail.matches?.length > 0">
        <div class="full-width mt-32 mb-16">
          <div
            class="f-body-16-medium t-fg-primary"
            v-text="$t('market.orderDetail.relatedMatches')"
          />
          <div class="p-info-card__icon p-info-card__icon__success" />
        </div>

        <p-loading v-if="loading" class="place-items-center" />
        <order-match
          v-for="(match, index) in detail.matches"
          v-else
          :key="index"
          :class="detail.matches > 1 && index !== 0 ? 'mt-16' : ''"
          :match="match"
          :market="selectedMarket"
        />
      </div>

      <template v-if="detail.status.includes('open')">
        <p-button button-type="ghost" class="full-width mt-24" @click="onCancel">
          <span class="t-fg-danger" v-text="$t('market.orderDetail.cancelled')" />
        </p-button>
      </template>
      <template v-else>
        <p-button button-type="ghost" class="full-width mt-24" @click="onRepeat">
          <span class="t-fg-primary" v-text="$t('general.repeatProcess')" />
          <p-icon color="fg-primary" icon="refresh" size="xmd" />
        </p-button>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ObjectRecord, OpenOrderConfig, MarketConfig } from '~/models'

const props = defineProps({
  detail: {
    type: Object as PropType<ObjectRecord>,
    default: null,
  },
  selectedMarket: {
    type: Object as () => MarketConfig | null,
    default: null,
  },
})

const emit = defineEmits(['repeat-submit', 'cancel-order'])

const loading = ref(false)

const getOrderTypeText = (type: string): string => {
  return useLookupsStore().getTypeHash[type]
    ? $t(useLookupsStore().getTypeHash[type])
    : $t('financialHistory.condition')
}

const marketPrecision = (): number => {
  return props.selectedMarket?.precisions?.amount || 2
}

const matchedAmount = (order: OpenOrderConfig): string => {
  const matched =
    order?.remaining_amount === 0
      ? order?.amount
      : minus({
          numA: order?.amount,
          numB: order?.remaining_amount,
          precision: marketPrecision(order?.market),
        })
  return `${formatNumber({ num: matched, precision: marketPrecision(order?.market) })} ${order?.market
    ?.split('_')[0]
    ?.toUpperCase?.()}`
}

const getStatusHash = (order: OpenOrderConfig): ObjectRecord => {
  return useLookupsStore().getStatusHash[order?.status ?? '']
}

const onRepeat = () => {
  emit('repeat-submit', props.detail)
}

const onCancel = () => {
  emit('cancel-order', props.detail?.uid)
}
</script>
