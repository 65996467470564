<template>
  <div v-if="isUserAuthenticated()" class="mt-32">
    <p-drawer v-if="selectedOrderHistory" :steps="step" @on-close="smartRouter.back()">
      <template #step1>
        <order-history-detail
          v-if="selectedOrderHistory"
          :detail="selectedOrderHistory"
          :selected-market="selectedMarket"
          @repeat-submit="repeatFormSubmit"
          @cancel-order="handleCancelOrder"
        />
      </template>
    </p-drawer>
  </div>
</template>

<script setup lang="ts">
import type { ObjectRecord, MarketConfig } from '~/models'

const route = useRoute()
const mainStore = useMainStore()

const selectedOrderHistory = ref<ObjectRecord | null>(null)

const selectedMarket = computed((): MarketConfig => {
  if (typeof selectedOrderHistory.value?.market === 'string') {
    return mainStore.getMarkets?.[selectedOrderHistory.value.market] || {}
  }
  return {}
})

const selectedMarketCurrency = computed(() =>
  mainStore.getCurrency(selectedOrderHistory.value?.market as string),)

const step = computed(() => {
  return {
    step1: {
      title: selectedMarketCurrency.value?.symbol?.toUpperCase?.(),
      back: false,
      headerTitleSlot: {
        icon: getAsset({ asset: selectedMarketCurrency.value?.icon, type: 'coin' }),
      },
    },
  }
})

const marketPrecision = (): number => {
  return selectedMarket.value?.precisions?.amount || 2
}

const totalPrice = (order: ObjectRecord) => {
  return formatNumber({
    num: mul({
      numA: Number(order?.average),
      numB: minus({
        numA: Number(order?.amount),
        numB: Number(order?.remaining_amount),
        precision: marketPrecision(order.market),
      }),
    }),
  })
}

const repeatFormSubmit = async (history: ObjectRecord) => {
  if (!history) return

  const order = history
  const payload = {
    type: order.type,
    price: order.price,
    amount: order.amount,
    market: order.market,
    trade: order.trade,
    total: totalPrice(order),
  }

  useLookupsStore().fetchEventDetail({
    order: 'market',
    trade: payload.trade,
    form_name: 'easyTrade',
    market: payload.market,
  })

  await useMarketStore()
    .order(payload)
    .finally(() => {
      smartRouter.back()
    })
}

const handleCancelOrder = (uid: string) => {
  const payload = {
    ids: [uid],
  }
  if (uid) {
    useMarketStore()
      .cancelOrder(payload)
      .finally(() => {
        smartRouter.back()
      })
  }
}

onMounted(async () => {
  const { orderId } = route.query
  const response = await useUserStore().fetchOrderDetails(orderId as string)
  selectedOrderHistory.value = response.payload
})
</script>
